import { ActionIcon, Anchor, Badge, Group, Text, Tooltip } from "@mantine/core";
import { IconFileArrowRight } from "@tabler/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FinanceableApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";

export const ScfPossibleProgramList = () => {
  const navigate = useNavigate();
  const { programStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    programStatus,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          navigate(`/invoice-possible/${item.id}/SCF/${item.productType === "SUPPLIER_LED" ? "SUPPLIER_LED" : "BUYER_LED"}`);
          break;

        default:
          break;
      }
    },
  });

  return (
    <Table name="financeable.program.lbf.list" columns={columns} filters={{ financeType: "SCF" }} loadData={(data) => FinanceableApi.programList(data!)} />
  );
};

const useHeader = ({ onClick, programStatus }: { onClick: (e1: string, e2: any) => void; programStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Боломжит нэхэмжлэх" position="bottom">
          <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
            <IconFileArrowRight />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.name || "-"}</Text>
          <Anchor># {record?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөрийн төрөл",
    render: (record) => {
      return <Text w="max-content">{record.productType || "-"}</Text>;
    },
  },
  {
    title: "Анкор эсэх",
    render: (record) => {
      return (
        <Badge size="sm" variant="outline" color={record.isProgramAnchor ? "green.5" : "red.5"}>
          {record.isProgramAnchor ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    render: (record) => {
      return record?.product ? (
        <Text w="max-content">
          <Text w="max-content">{record?.product?.name || "-"}</Text> <Anchor># {record?.product?.refCode || "-"}</Anchor>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    render: (record) => {
      return Tag(
        programStatus.find((item: IReference) => item.code === record.programStatus)?.color || "",
        programStatus.find((item: IReference) => item.code === record.programStatus)?.name || "-",
      );
    },
  },
  {
    title: "Санхүүжилт авсан нийт дүн",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record?.utilizedAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Нийт баталсан лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record?.programLimit || 0)}
        </Text>
      </Group>
    ),
  },
];

import { ActionIcon, Flex, Group, Text } from "@mantine/core";
import { IconCheck, IconTrash, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ColumnType, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { NumberCurrencyField } from "../form/number-currency-field";
import { Tag } from "./../tags";

export const InvInfoTable = ({ setFieldValue, invoices, data }: { setFieldValue: (e1: string, e2: any) => void; invoices: any[]; data: any }) => {
  const { currencies, invoicePaymentStatus, invoiceStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const type = user?.currentBusiness?.type;

  const tableRef = useRef<any>(null);

  const columns = useHeader({
    currencyFormat,
    Tag,
    setFieldValue,
    onClick: (key, record) => {
      switch (key) {
        case "remove":
          if (invoices.length > 1) {
            setFieldValue(
              "invoices",
              invoices.filter((item: any) => item.id !== record?.id),
            );
          } else {
            message.error("Хамгийн багадаа 1 нэхэмжлэх байх ёстой!");
          }
          break;
        default:
          break;
      }
    },
    invoicePaymentStatus,
    currencies,
    invoiceStatus,
    product: data.product,
    type: type || "",
  });

  return (
    <div>
      <Table ref={tableRef} name="selected.programs.invoices.list" columns={columns} filters={{}} minWidth={2400} dataSource={invoices} />
    </div>
  );
};

const useHeader = ({
  onClick,
  invoicePaymentStatus,
  currencies,
  invoiceStatus,
  currencyFormat,
  setFieldValue,
  Tag,
  product,
  type,
}: {
  onClick: (key: string, record: any) => void;
  invoicePaymentStatus: IReference[];
  currencies: IReference[];
  invoiceStatus: IReference[];
  currencyFormat: any;
  Tag: (e1: string, e2: string) => void;
  setFieldValue: (e1: string, e2: any) => void;
  product: any;
  type: string;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Хасах",
    render: (record) => (
      <div>
        <ActionIcon variant="light" color="red" onClick={() => onClick("remove", record)}>
          <IconTrash size={20} />
        </ActionIcon>
      </div>
    ),
  },
  {
    title: "Нэхэмжлэх #",
    render: (record) => (
      <Flex direction="column">
        <Text color="golomt" fw={500}>
          # {record?.refCode || "-"}
        </Text>
        <Text fw={500} color="gray.7" w="max-content">
          {dayjs(record?.createdAt).format("YYYY-MM-DD HH:mm")}
        </Text>
      </Flex>
    ),
  },
  // {
  //   title: "Борлуулалтын захиалга",
  //   render: (record) => (
  //     <Text color="golomt" fw={500} w={"max-content"}>
  //       # {record?.salesOrder?.refCode || "-"}
  //     </Text>
  //   ),
  // },
  // {
  //   title: "Худалдан авагчийн захиалга",
  //   render: (record) => (
  //     <Text color="golomt" fw={500} w={"max-content"}>
  //       # {record?.purchaseOrder?.refCode || "-"}
  //     </Text>
  //   ),
  // },
  {
    title: "Худалдан авагч бизнес",
    render: (record) => (
      <Text w={"max-content"}>
        {record?.type !== "SALES" ? (
          <Flex direction="column">
            <Text color="gray.7" fw={500}>
              {record?.senderBusiness?.profileName || "-"}
            </Text>
            <Text fw={500} color="golomt">
              # {record?.senderBusiness?.refCode || "-"}
            </Text>
          </Flex>
        ) : (
          <Flex direction="column">
            <Text color="gray.7" fw={500}>
              {record?.receiverBusiness?.profileName || "-"}
            </Text>
            <Text fw={500} color="golomt">
              # {record?.receiverBusiness?.refCode || "-"}
            </Text>
          </Flex>
        )}
      </Text>
    ),
  },
  {
    title: "Худалдан авагч партнер",
    render: (record) =>
      record?.type !== "SALES" ? (
        <Flex direction="column">
          <Text color="gray.7" fw={500}>
            {record?.senderBusiness?.partner?.profileName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ) : (
        <Flex direction="column">
          <Text color="gray.7" fw={500}>
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
          <Text fw={500} color="golomt">
            # {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
        </Flex>
      ),
  },
  {
    title: "Хүсэлтийн дүн",
    render: (record, index) => (
      <Flex w={"300px"}>
        <NumberCurrencyField
          onChangeCustom={() => {
            setFieldValue(`invoices[${index}].requestedId`, record?.id);
          }}
          name={`invoices[${index}].requestedAmount`}
          placeholder="дүн"
        />
      </Flex>
    ),
  },
  {
    title: "Үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text color="gray.7" fw={500} w={"max-content"}>
          {record?.amountToPay ? currencyFormat(record?.amountToPay || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: "Валют",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {currencies?.find((f: any) => f?.code === record?.currency)?.name || "Төгрөг"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Санхүүжилтийн шимтгэл",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {type === "BUYER"
          ? currencyFormat(((record?.requestedAmount * product?.buyerFee) / product?.buyerCalculateDay / 100) * product.buyerTerm)
          : currencyFormat(
              ((record?.requestedAmount || 0) * ((product?.suppDisbursementFee || 0) + (product?.suppFee || 0) / (product?.suppCalculateDay || 0))) / 100 +
                (product?.suppAppFee || 0),
            )}
      </Text>
    ),
  },
  {
    title: "Тооцсон шимтгэл ",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {type === "BUYER"
          ? currencyFormat(((record.requestedAmount || 0) * (product?.buyerFee || 0)) / (product?.buyerCalculateDay || 0) / 100)
          : currencyFormat(((record.requestedAmount || 0) * (product?.suppFee || 0)) / (product?.suppCalculateDay || 0) / 100)}
      </Text>
    ),
  },
  {
    title: "Олголтын шимтгэл",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {type === "BUYER"
          ? currencyFormat(((record?.requestedAmount || 0) * (product?.buyerDisbursementFee || 0)) / 100)
          : currencyFormat(((record?.requestedAmount || 0) * (product?.suppDisbursementFee || 0)) / 100)}
      </Text>
    ),
  },
  {
    title: "Өргөдлийн хураамж",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {type === "BUYER" ? currencyFormat(product?.buyerAppFee || 0) : currencyFormat(product?.suppAppFee || 0)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх - Min хувь",
    render: (record) => (
      <Flex align="center" gap={8}>
        {product?.minOfInv <= record?.requestedAmount ? <IconCheck color="green" /> : <IconX color="red" />}
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(((record?.requestedAmount || 0) * (product?.minOfInv || 0)) / 100)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх - Max хувь",
    render: (record) => (
      <Flex align="center" gap={8}>
        {product?.maxOfInv <= record?.requestedAmount ? <IconCheck color="green" /> : <IconX color="red" />}
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(((record?.requestedAmount || 0) * (product?.maxOfInv || 0)) / 100)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх доод үлдэгдэл",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {currencyFormat(product?.minInvAmount || 0)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх Tenor",
    render: (record) => (
      <Flex align={"center"} gap={8}>
        {product?.minTenor <= moment.duration(moment(record?.paymentDate).diff(new Date())).days() &&
        moment.duration(moment(record?.paymentDate).diff(new Date())).days() <= product?.maxTenor ? (
          <IconCheck color="green" />
        ) : (
          <IconX color="red" />
        )}
        <Text fw={500} color="gray.7" w="max-content">
          {moment.duration(moment(record?.paymentDate).diff(new Date())).days()}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх хугацаа",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: type === "SUPPLIER" ? "Буцаан дуудах огноо" : "Санхүүжилтийн хугацаа",
    render: (record) =>
      type === "SUPPLIER" ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.paymentDate ? dateTimeFormat(moment(new Date(record?.paymentDate)).add(product?.recourseDays, "days").toISOString()) : "-"}
        </Text>
      ) : (
        <Text>{product.buyerTerm} хоног</Text>
      ),
  },
  {
    title: "Санхүүжилт зогсоох огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.paymentDate
          ? dateTimeFormat(
              moment(new Date(moment(new Date(record?.paymentDate)).add(product?.recourseDays, "days").toISOString()))
                .add(product?.suppTerminatePeriod, "days")
                .toISOString(),
            )
          : "-"}
      </Text>
    ),
  },
];

import {
  Anchor,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTwitter } from "@tabler/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useSWR from "swr";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import { Form } from "../../components/form";
import { NumberField } from "../../components/form/number-field";
import { OtpFieldInput } from "../../components/form/otp-field-input";
import { PasswordField } from "../../components/form/password-field";
import { authChange } from "../../store/Auth";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/Message";
import { ReactComponent as Dot1 } from "./../../assets/dot1.svg";
import { ReactComponent as Dot2 } from "./../../assets/dot2.svg";
import { ReactComponent as Dot3 } from "./../../assets/dot3.svg";
import HeroIcon from "./../../assets/hero-icon-2.webp";

const schema = yup.object({
  pin: yup
    .string()
    .typeError("Зөвхөн тоон утга оруулна!")
    .matches(/^[0-9]{6}$/, "Зөвхөн тоон утга оруулна!")
    .max(6, "Хэт урт байна!")
    .min(6, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

type IForm = {
  pin: undefined | string | number;
  otpMethod: string;
};

export default function Verify() {
  const { id } = useParams();
  const { classes, theme } = useStyles();
  const [verifyAction, setVerifyAction] = useState<any[]>(["loading"]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(0);
  const [phoneVerify, setPhoneVerify] = useState<any>();
  const [isTerm, setIsTerm] = useState<boolean>(false);

  const { data: getData, isLoading } = useSWR<any>(
    id ? `swr.api.auth.${id}.verify` : null,
    async () => {
      if (id) {
        try {
          const res = await AuthApi.verify(id!);
          return res;
        } catch (error: any) {
          message.error(error?.message);
        }
      } else return null;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const onSubmit = async (values: IForm) => {
    let res;
    setLoading(true);
    try {
      res = await AuthApi.otpVerify({ otpCode: `${values.pin}`, otpMethod: `${getData?.sessionScope}` });

      setVerifyAction(["password", res]);
      message.success(`Утас амжилттай баталгаажлаа. Та Finance системд нэвтрэх нууц үгээ оруулна уу. ${dateTimeFormat(new Date())}`);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  const otpGet = async (data: any) => {
    let res: any = {};
    try {
      res = await AuthApi.otpGet({ otpMethod: data.sessionScope });
      setCounter(res?.expiryIn);
      setVerifyAction(["verify", res]);
      message.success(res?.message);
    } catch (error: any) {
      message.error(error?.message || "Хүсэлт амжилтгүй");
    }
  };

  const onSubmitPhoneCheck = async (values: any) => {
    setLoading(true);
    setPhoneVerify({ otpMethod: `${verifyAction[1]?.sessionScope}`, phone: `${values?.phone}` });
    try {
      let res = await AuthApi.phoneCheck({ otpMethod: `${verifyAction[1]?.sessionScope}`, phone: `${values?.phone}` });
      console.log(res);
      setCounter(res?.expiryIn);
      setVerifyAction(["verify", res]);
    } catch (error: any) {
      message.error(error.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const onSubmitPhoneReload = async (values: any) => {
    setLoading(true);
    try {
      let res = await AuthApi.phoneCheck({ otpMethod: `${values.otpMethod}`, phone: `${values?.phone}` });

      setCounter(res?.expiryIn || 0);
      setVerifyAction(["verify", res]);
    } catch (error: any) {
      message.error(error.message);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (getData?.sessionScope === "FORGOT") {
      dispatch(authChange(getData));

      if (getData?.isClicked) {
        setVerifyAction(["phone", getData]);
      } else otpGet(getData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  React.useEffect(() => {
    if (counter > 0 && verifyAction[0] && verifyAction[0] === "verify") {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, verifyAction]);

  return (
    <Box className={classes.layout}>
      <div className={classes.dotWrapper}>
        <Dot1 className={classes.dot1} />
        <Dot2 className={classes.dot2} />
        <Dot3 className={classes.dot3} />
        <Dot3 className={classes.dot4} />
      </div>
      <Grid p={"lg"} gutter={"lg"} my="auto">
        <Grid.Col sm={12} md={12} lg={6}>
          <Group position="center" w={"100%"} h={"100%"}>
            <Flex className={classes.zIndex1} justify="center" direction="column" p="lg">
              <Text mb="xl" fw={600} fz={42} color="white" lh={1}>
                Үнэ цэн нэмсэн <br /> түншлэлийн санхүүжилт.
              </Text>
              <Image src={HeroIcon} width={700} height={500} className={classes.heroImage} />
            </Flex>
          </Group>
        </Grid.Col>
        <Grid.Col sm={12} md={12} lg={6}>
          <Flex w="100%" align="center" justify="center" h="100%">
            <Paper w={"700px"} p={"lg"} bg={"gray.0"} radius={"md"} className={classes.zIndex1}>
              <Box pos={"relative"}>
                <Text color="violet" fz={"xl"} fw={600} p={"lg"}>
                  Finance систем
                </Text>

                {verifyAction[0] === "loading" && (
                  <Paper px={"xs"} h={"120px"}>
                    <Center>
                      <LoadingOverlay visible color="violet" />
                    </Center>
                  </Paper>
                )}

                <Paper px={"xl"} radius={0}>
                  {verifyAction[0] === "password" && (
                    <Form
                      initialValues={{
                        password: undefined,
                        oldPassword: undefined,
                      }}
                      onSubmit={async (values) => {
                        setLoading(true);
                        try {
                          await AuthApi.password(values);
                          const now = new Date();
                          message.success(`Бүртгэл амжилттай баталгаажлаа. Та нэвтэрнэ үү. ${dateTimeFormat(now)} `);
                          window.location.href = "https://cp.dehub.mn?verifyConfirmType=confirmed-bogd";
                        } catch (error: any) {
                          message.error(error?.message || "үйлдэл амжилтгүй!");
                        }
                        setLoading(false);
                      }}
                      validationSchema={yup.object({
                        password: yup.string().required("Заавал бөглөнө!"),
                        oldPassword: yup
                          .string()
                          .oneOf([yup.ref("password"), null], "Давтан нууц үг таарах ёстой!")
                          .required("Заавал бөглөнө!"),
                      })}>
                      {() => {
                        return (
                          <div>
                            <Grid>
                              <Grid.Col>
                                <Input.Wrapper label="Таны хэрэглэгчийн нэр">
                                  <Input placeholder="Таны хэрэглэгчийн нэр" disabled />
                                </Input.Wrapper>
                              </Grid.Col>

                              <Grid.Col h={90} span={12}>
                                <PasswordField name="password" placeholder="*******" label="Нууц үг үүсгэх" />
                              </Grid.Col>
                              <Grid.Col h={90} span={12}>
                                <PasswordField name="oldPassword" placeholder="*******" label="Нууц үгийг давтах" />
                              </Grid.Col>
                              <Grid.Col>
                                <Text color="gray.9" fw={600}>
                                  Систем ашиглах санамж, нөхцөлтэй{" "}
                                  <Text component="span">
                                    <Anchor color="violet">ЭНД</Anchor>{" "}
                                  </Text>{" "}
                                  танилцана уу.
                                </Text>
                                <Space h={"lg"} />
                                <Checkbox onChange={(e) => setIsTerm(e.target.checked)} checked={isTerm} color="gray" label="Би танилцаж бүрэн ойлголоо." />
                              </Grid.Col>
                            </Grid>
                            <Group py={"lg"} position="left">
                              <Button
                                loading={isLoading}
                                disabled={!isTerm}
                                variant="gradient"
                                type="submit"
                                size="md"
                                w="200px"
                                style={{ background: theme.fn.linearGradient(95, "pink", "pink.6", "indigo.4") }}>
                                Нууц үг үүсгэх
                              </Button>
                            </Group>
                          </div>
                        );
                      }}
                    </Form>
                  )}

                  {verifyAction[0] === "verify" && (
                    <Form
                      initialValues={{
                        pin: "",
                      }}
                      onSubmit={onSubmit}
                      validationSchema={schema}>
                      {() => {
                        return (
                          <div>
                            <Grid>
                              <Grid.Col>
                                <Text>И-мэйл амжилттай баталгаажлаа.</Text>
                                <Text>
                                  Таны{" "}
                                  <Text fw={600} color="violet" component="span">
                                    {(verifyAction[1] && verifyAction[1]?.phone) || "*******"}
                                  </Text>{" "}
                                  утасны дугаарт баталгаажуулах код илгээлээ.
                                </Text>
                                <Text>
                                  Та уг кодыг доор оруулна уу. Хүлээх хугацаа{" "}
                                  <Text fw={500} component="span">
                                    {counter}
                                  </Text>{" "}
                                  сек.
                                </Text>
                              </Grid.Col>

                              <Grid.Col h={90} span={12}>
                                <OtpFieldInput name="pin" size="lg" />
                              </Grid.Col>
                              <Grid.Col>
                                <Text>
                                  Код ирэхгүй эсвэл кодын хугацаа дуусвал{" "}
                                  <Text component="span">
                                    <Anchor onClick={() => onSubmitPhoneReload(phoneVerify)}>ЭНДЭЭС</Anchor>{" "}
                                  </Text>{" "}
                                  дахин авна уу.
                                </Text>
                                <Text>Танд амжилт хүсье!</Text>
                              </Grid.Col>
                            </Grid>
                            <Group py={"lg"} position="left">
                              <Button
                                disabled={counter === 0}
                                variant="gradient"
                                type="submit"
                                size="md"
                                w="200px"
                                style={{ background: theme.fn.linearGradient(95, "pink", "pink.6", "indigo.4") }}>
                                Баталгаажуулах
                              </Button>
                            </Group>
                          </div>
                        );
                      }}
                    </Form>
                  )}
                  {verifyAction[0] === "phone" && (
                    <>
                      <Form
                        initialValues={{ phone: undefined }}
                        onSubmit={onSubmitPhoneCheck}
                        validationSchema={yup.object({
                          phone: yup
                            .string()
                            .typeError("Зөвхөн тоон утга оруулна!")
                            .matches(/^[(9|8|6]{1}[0-9]{7}$/, "Зөвхөн утасны дугаар оруулна!")
                            .required("Заавал бөглөнө!")
                            .nullable(),
                        })}>
                        {() => {
                          return (
                            <Paper py={"lg"}>
                              <Grid>
                                <Grid.Col>
                                  <Text>И-мэйл амжилттай баталгаажлаа.</Text>
                                  <Text>
                                    Та{" "}
                                    <Text fw={600} color="violet" component="span">
                                      {(verifyAction[1] && verifyAction[1]?.phone) || "*******"}
                                    </Text>{" "}
                                    утасны дугаараа баталгаажуулна уу.
                                  </Text>
                                </Grid.Col>
                              </Grid>
                              <Space h={"xs"} />
                              <NumberField
                                iconWidth={60}
                                icon={<Text fz={"sm"}>(+976)</Text>}
                                name="phone"
                                label="Баталгаажжулах утас"
                                required
                                placeholder="99********"
                              />
                              <Space h={"lg"} />
                              <Group position="right">
                                <Button type="submit" variant="gradient">
                                  Баталгаажуулах
                                </Button>
                              </Group>
                            </Paper>
                          );
                        }}
                      </Form>
                    </>
                  )}
                </Paper>
                <LoadingOverlay visible={loading} />
              </Box>
            </Paper>
          </Flex>
        </Grid.Col>
      </Grid>
      <Box py="lg" className={classes.footer}>
        <Divider color={"gray"} />
        <Flex py={"lg"} w={"100%"} justify={"space-between"}>
          <Text size={"lg"} color="gray">
            © {new Date().getFullYear()}, Digital Era Payment
          </Text>
          <Text size={"lg"} color="gray">
            <Group>
              <IconBrandTwitter />
              <IconBrandFacebook />
              <IconBrandInstagram />
            </Group>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}

const useStyles = createStyles(() => ({
  layout: {
    background: "#34105e",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  zIndex1: {
    zIndex: 1,
  },
  dotWrapper: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  dot1: {
    position: "absolute",
    top: 200,
    width: 300,
    height: "auto",
  },
  dot2: {
    position: "absolute",
    top: 400,
    left: 300,
    width: 300,
    height: "auto",
  },
  dot3: {
    position: "absolute",
    left: 500,
    width: 300,
    height: "auto",
  },
  dot4: {
    position: "absolute",
    top: 100,
    right: 60,
    width: 300,
    height: "auto",
  },

  heroImage: {
    margin: "0 auto",
    [`@media screen and (max-width: 575px)`]: {
      display: "none",
    },
  },

  footer: {
    paddingLeft: 100,
    paddingRight: 100,
    [`@media screen and (max-width: 575px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}));

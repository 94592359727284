import { ActionIcon, Avatar, Drawer, Flex, Group, Tabs, Text, Tooltip } from "@mantine/core";
import { IconFilter, IconInfoSquare, IconPrinter } from "@tabler/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ScfRequestApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { ReceivedLendList } from "../../components/received-lend/list";
import { RequestFundingDetails } from "../../components/request-funding/details";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const ReceivedList = () => {
  const breadcrumbs = useBreadcrumb();
  const { currencies } = useSelector((state: any) => state.general);
  const { scfRequestStatus, fundingInstructionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [tabKey, setTabKey] = useState<string>("2");
  const [action, setAction] = useState<any[]>([]);

  const columns = useHeader({
    currencies,
    scfRequestStatus,
    Tag,
    fundingInstructionStatus,
    onClick: (key, record) => {
      setAction([key, record]);
    },
  });

  return (
    <PageLayout
      title="Авсан санхүүжилт"
      subTitle="Авсан санхүүжилт жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <ActionIcon variant="light" size={36} color="golomt">
            <IconPrinter size={25} />
          </ActionIcon>
          <ActionIcon variant="light" size={36} color="golomt">
            <IconFilter size={25} />
          </ActionIcon>
        </Group>,
      ]}>
      <Tabs
        value={tabKey}
        onTabChange={(e: string) => {
          setTabKey(e);
        }}>
        <Tabs.List>
          {/* <Tabs.Tab py={"md"} value="1">
            Зээлийн шугам ашигласан
          </Tabs.Tab> */}
          <Tabs.Tab py={"md"} value="2">
            Нэхэмжлэх барьцаалах
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1">{tabKey === "1" && <ReceivedLendList />}</Tabs.Panel>
        <Tabs.Panel value="2">
          {tabKey === "2" && (
            <Table
              minWidth={2400}
              name={`invoice.requests.completedList`}
              columns={columns}
              filters={{ financeType: "SCF" }}
              loadData={(data) => ScfRequestApi.completedList(data!)}
            />
          )}
        </Tabs.Panel>
      </Tabs>
      <Drawer
        styles={{ drawer: { overflowY: "scroll" } }}
        zIndex={100}
        opened={action[0] === "detail"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        padding="xl"
        size="80rem">
        <RequestFundingDetails action={action} setAction={setAction} />
      </Drawer>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  currencies,
  scfRequestStatus,
  Tag,
  fundingInstructionStatus,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  scfRequestStatus: any;
  Tag: any;
  fundingInstructionStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Дэлгэрэнгүй" position="bottom">
        <ActionIcon variant="light" onClick={() => onClick("detail", record)} color="golomt">
          <IconInfoSquare />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хүсэлтийн код",
    render: (record) => (
      <Text color="golomt" fw={500} w={"max-content"}>
        # {record?.refCode || "-"}
      </Text>
    ),
    width: "10px",
  },
  {
    title: "Нэхэмжлэх",
    render: (record) => (
      <Text fw={500} color="golomt" w={"max-content"}>
        # {record?.invRefCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хүсэлт төлөв",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {Tag(
          scfRequestStatus.find((item: IReference) => item.code === record?.requestStatus)?.color,
          scfRequestStatus.find((item: IReference) => item.code === record?.requestStatus)?.name || "-",
        )}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    render: (record) => (
      <Group position="right">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.invAmountToPay || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Хүсэлтийн дүн",
    render: (record) => (
      <Group position="right">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.requestedAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Валют",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {currencies?.find((f: any) => f?.code === record?.program?.currency)?.name || "Төгрөг"}
      </Text>
    ),
  },
  {
    title: "Хоног",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.financePeriod}
      </Text>
    ),
  },
  {
    title: "Санхүүжилтийн шимтгэл",
    render: (record) => (
      <Group position="right">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.totalScfFeeAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Банк шимтгэл",
    render: (record) => (
      <Group position="right">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.bankFeeAmount || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Хөтөлбөр нэр",
    render: (record) =>
      record?.program ? (
        <Text w="max-content">
          <Text fw={500} color="gray.7" w="max-content">
            {record?.program?.name || "-"}
          </Text>
          <Text color="golomt"># {record?.program?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Бүтээгдэхүүн",
    render: (record) =>
      record?.product ? (
        <Text w="max-content">
          <Text fw={500} color="gray.7" w="max-content">
            {record?.product?.name || "-"}
          </Text>
          <Text color="golomt"># {record?.product?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Нэхэмжлэх огноо",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {record?.invCreatedAt ? dateTimeFormat(record?.invCreatedAt) : "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх баталсан",
    render: (record) => (
      <Text color="gray.7" fw={500}>
        {record?.invConfirmedDate ? dateTimeFormat(record?.invConfirmedDate) : "-"}
      </Text>
    ),
  },
  // {
  //   title: "Худалдан авагч бизнес",
  //   render: (record) => {
  //     if (record?.type === "BUYER")
  //       return record?.buyerBusiness ? (
  //         <Text fw={500} color="gray.7" w="max-content">
  //           {record?.buyerBusiness?.profileName} <Text color="golomt"> # {record?.buyerBusiness?.refCode || "-"}</Text>
  //         </Text>
  //       ) : (
  //         "-"
  //       );
  //     else
  //       return record?.invReceiverBusiness ? (
  //         <Text fw={500} color="gray.7" w="max-content">
  //           {record?.invReceiverBusiness?.profileName} <Text color="golomt"> # {record?.invReceiverBusiness?.refCode || "-"}</Text>
  //         </Text>
  //       ) : (
  //         "-"
  //       );
  //   },
  // },
  // {
  //   title: "Худалдан авагч",
  //   render: (record) => {
  //     if (record?.type === "BUYER")
  //       return record?.requestedBusiness?.partner ? (
  //         <Text fw={500} color="gray.7" w="max-content">
  //           {record?.requestedBusiness?.partner?.businessName || "-"} <Text color="golomt"> # {record?.requestedBusiness?.partner?.refCode || "-"}</Text>
  //         </Text>
  //       ) : (
  //         "-"
  //       );
  //     else
  //       return record?.invReceiverBusiness?.partner ? (
  //         <Text fw={500} color="gray.7" w="max-content">
  //           {record?.invReceiverBusiness?.partner?.businessName} <Text color="golomt"> # {record?.invReceiverBusiness?.partner?.refCode || "-"}</Text>
  //         </Text>
  //       ) : (
  //         "-"
  //       );
  //   },
  // },
  {
    title: "Худалдан авагч бизнес",
    render: (record) => {
      return record?.buyerBusiness ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.buyerBusiness?.profileName} <Text color="golomt"> # {record?.buyerBusiness?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Худалдан авагч",
    render: (record) => {
      return record?.buyerBusiness?.partner ? (
        <Text fw={500} color="gray.7" w="max-content">
          {record?.buyerBusiness?.partner?.businessName} <Text color="golomt"> # {record?.buyerBusiness?.partner?.refCode || "-"}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    render: (record) =>
      record?.requestedUser ? (
        <Flex gap={8} align="center">
          <Avatar src={record?.requestedUser?.avatar} />
          <Text fw={500} color="gray.7" w="max-content" tt={"capitalize"}>
            {record?.requestedUser?.lastName ? record?.requestedUser?.lastName[0] + ". " : ""} {record?.requestedUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Банк ажилтан",
    render: (record) =>
      record?.respondedUser ? (
        <Flex gap={8} align="center">
          <Avatar src={record?.respondedUser?.avatar} />
          <Text fw={500} color="gray.7" w="max-content" tt={"capitalize"}>
            {record?.respondedUser?.lastName ? record?.respondedUser?.lastName[0] + ". " : ""} {record?.respondedUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Олгох дүн",
    render: (record) => (
      <Flex align="center" justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.approvedAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Шийдвэрлэх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {dayjs(record?.responseDate).format("YYYY-MM-DD HH:mm")}
      </Text>
    ),
  },
  {
    title: "Олгох огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.toDisburseDate ? dateTimeFormat(record?.toDisburseDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Эргэн төлөх дүн",
    render: (record) => (
      <Flex justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {currencyFormat(record?.repaymentAmount || 0)}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Эргэн төлөх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" lineClamp={1}>
        {record?.repaymentDate ? dateTimeFormat(record?.repaymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    render: (record) =>
      record?.repaymentInv ? (
        <Text fw={500} color="golomt">
          # {record?.repaymentInv.refCode || "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Олгосон",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.disbursedDate ? dateTimeFormat(record?.disbursedDate) : "-"}
      </Text>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Авсан санхүүжилт",
  },
];

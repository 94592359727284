import { ActionIcon, Anchor, Avatar, Group, Paper, Switch, Text, Tooltip } from "@mantine/core";
import { IconCircleMinus, IconFileText } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ProgramparticipantApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const ProgramBuyer = ({ data }: { data?: any }) => {
  const params = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<any>({ programId: params.id });
  const { networkPenaltyTypes, programParticipantStatus, networkDiscountTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    programParticipantStatus,
    networkPenaltyTypes,
    networkDiscountTypes,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          break;
        case "status":
          break;

        default:
          break;
      }
    },
  });

  return (
    <Paper>
      <Text c={"gray.6"} fw={500} py="lg">
        Нэхэмжлэх санхүүжүүлэх үеийн нөхцөл
      </Text>
      <Table minWidth={2400} name="program.participant.list" columns={columns} filters={filters} loadData={(data) => ProgramparticipantApi.list(data)} />
    </Paper>
  );
};

const useHeader = ({
  onClick,
  programParticipantStatus,
  networkDiscountTypes,
  networkPenaltyTypes,
}: {
  onClick: (e1: string, e2: any) => void;
  programParticipantStatus: IReference[];
  networkDiscountTypes: IReference[];
  networkPenaltyTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    dataIndex: "description",
    render: (record) => {
      return (
        <Group noWrap>
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconFileText />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Төлөв" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconCircleMinus />
            </ActionIcon>
          </Tooltip>
        </Group>
      );
    },
  },
  // {
  //   title: "#",
  //   render: (record) => {
  //     return (
  //       <div>
  //         <Checkbox />
  //       </div>
  //     );
  //   },
  //   width: "30px",
  // },
  {
    title: "ТТД",
    dataIndex: "sku",
    render: (record) => {
      return <Text w={"max-content"}>{record.business?.regNumber || "-"}</Text>;
    },
  },
  {
    title: "Партнерийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return record.business?.partner ? (
        <Text w={"max-content"}>
          <Anchor># {record.business?.partner?.refCode} ,</Anchor>
          <Text>{record.business?.partner?.businessName || "-"}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "SME бизнесийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          <Anchor># {record.business?.refCode} ,</Anchor>
          <Text>{record.business?.profileName || "-"}</Text>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр дэх статус",
    dataIndex: "sku",
    render: (record) => {
      return Tag(
        programParticipantStatus?.find((item: IReference) => item.code === record.participantStatus)?.color || "gray",
        programParticipantStatus?.find((item: IReference) => item.code === record.participantStatus)?.name || "-",
      );
    },
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text w={"max-content"}>{record?.areaRegion?.name || "-"}</Text>
        </div>
      );
    },
  },
  {
    title: "Чиглэл",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text w={"max-content"}>{record?.areaDirection?.name || "-"}</Text>
        </div>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    dataIndex: "sku",
    render: (record) => {
      return record?.paymentTerm ? (
        <Text w={"max-content"}>
          <Text color={"golomt"}>#{record?.paymentTerm?.refCode}</Text>
          <Text color="dimmed">{record?.paymentTerm?.description}</Text>
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгчээс олгосон авлага лимит",
    dataIndex: "sku",
    width: 120,
    render: (record) => <Text align="end">{currencyFormat(record?.debtLimitAmount || 0)}</Text>,
  },
  {
    title: "Лимит эхлэх огноо",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{dateTimeFormat(record.debtLimitStartDate)}</Group>;
    },
  },
  {
    title: "Лимит дуусах огноо",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{dateTimeFormat(record.debtLimitEndDate)}</Group>;
    },
  },
  {
    title: "Хөнгөлөлттэй эсэх",
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Switch checked={record?.earlyDiscount} offLabel={<Text fz={"10px"}>Үгүй</Text>} onLabel={<Text fz={"10px"}>Тийм</Text>} />
        </div>
      );
    },
  },
  {
    title: "Хөнгөлөлт хувь",
    dataIndex: "sku",
    render: (record) => {
      return <Group>{record?.discountPercent || 0} %</Group>;
    },
  },
  {
    title: "Хөнгөлөлт арга",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text w={"max-content"}>
          {networkDiscountTypes?.length > 0 ? networkDiscountTypes.find((item: IReference) => item.code === record.discountType)?.name || "-" : "-"}
        </Text>
      );
    },
  },
  {
    title: "Алдангитай эсэх",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group>
          <Switch checked={record?.countPenalty} offLabel={<Text fz={"10px"}>Үгүй</Text>} onLabel={<Text fz={"10px"}>Тийм</Text>} />
        </Group>
      );
    },
  },
  {
    title: "Алданги хувь",
    dataIndex: "sku",
    render: (record) => {
      return <>{record?.penaltyPercent} %</>;
    },
  },
  {
    title: "Алданги арга",
    dataIndex: "sku",
    render: (record) => {
      return <Text w={"max-content"}>{networkPenaltyTypes?.find((item: IReference) => item.code === record.penaltyType)?.name || "-"} </Text>;
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 7 хоног",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.weekAvgDebtAmount)}
        </Group>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - 14 хоног",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.halfMonthAvgDebtAmount)}
        </Group>
      );
    },
  },
  {
    title: "Авлагын дундаж үлдэгдэл - Сар",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgDebtAmount)}
        </Group>
      );
    },
  },
  {
    title: "Буцаалтын дундаж дүн - Сар",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgRefundAmount)}
        </Group>
      );
    },
  },
  {
    title: "Сарын дундаж татан авалт",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgPurchaseAmount)}
        </Group>
      );
    },
  },
  {
    title: "Сарын дундаж орлого тушаалт",
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right" noWrap>
          {currencyFormat(record?.monthAvgBidAmount)}
        </Group>
      );
    },
  },
  {
    title: "Ангилал",
    dataIndex: "sku",
    render: (record) => {
      if (record?.classificationCategory)
        return (
          <Text w={"max-content"}>
            <Text>{record?.classificationCategory?.name || "-"}</Text>
            <Text size={"sm"} color="dimmed" truncate>
              {record?.classificationCategory?.description || "-"}
            </Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Зэрэглэл",
    dataIndex: "sku",
    render: (record) => {
      if (record?.classificationPriority)
        return (
          <Text w={"max-content"}>
            <Text>{record?.classificationPriority?.name || "-"}</Text>
            <Text maw={"320px"} size={"sm"} color="dimmed" truncate>
              {record?.classificationPriority?.description || "-"}
            </Text>
          </Text>
        );
      else return "-";
    },
  },
  {
    title: "Хариуцсан санхүү",
    dataIndex: "sku",
    render: (record) => {
      if (record?.areaSetUser)
        return (
          <Group noWrap>
            <Avatar src={record?.areaSetUser?.avatar || ""} />
            <Text>
              {record?.areaSetUser?.lastName ? record.areaSetUser.lastName[0] + ". " : ""} {record?.areaSetUser?.firstName || ""}
            </Text>
          </Group>
        );
      else return "-";
    },
  },
];

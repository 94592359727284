import { Badge, MantineColor } from "@mantine/core";
import { ColorFormat } from "@mantine/core/lib/ColorPicker/types";

function Tag(color: MantineColor | ColorFormat, name: string) {
  if (color && color?.slice(0, 1) === "#")
    return (
      <Badge size="md" variant="outline">
        {name || "-"}
      </Badge>
    );
  else
    return (
      <Badge color={color} size="md" variant="outline">
        {name || "-"}
      </Badge>
    );
}

export { Tag };

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionIcon, Drawer, Modal, Tabs, Text } from "@mantine/core";
import { IconFileCheck, IconFileLike, IconListDetails, IconMail, IconTimeline, IconX } from "@tabler/icons";
import React from "react";
import { ScfRequestApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { RepaymentDetail } from "../../components/repayment/detail";
import { EmailNotify } from "../../components/repayment/email-notify";
import { PaymentHistory } from "../../components/repayment/payment-history";
import { RepaymentPinForm } from "../../components/repayment/pin-form";
import { PromiseHistory } from "../../components/repayment/promise-history";
import { RememberHistory } from "../../components/repayment/remember-history";
import { message } from "../../utils/Message";

export const Repayment = ({ action, setAction, reloadTable }: { action: any[]; setAction: (e: any) => void; reloadTable: any }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [drawerTab, setDrawerTab] = React.useState<string>("1");
  const [selectedData, setSelectedData] = React.useState<any>({});
  const [draftedData, setDraftedData] = React.useState<any>({});
  const [pinModal, setPinModal] = React.useState<boolean>(false);

  const sendRequest = async () => {
    setLoading(true);
    try {
      await ScfRequestApi.create(draftedData);
      setLoading(false);
      reloadTable();
      setAction([]);
      setPinModal(false);

      message?.success("SCF амжилттай баталгаажууллаа");
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  return (
    <div>
      <Drawer
        styles={{ drawer: { overflowY: "scroll" } }}
        zIndex={100}
        opened={action[0] === "drawer"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        padding="md"
        size="80rem">
        <PageLayout
          extra={[
            <ActionIcon
              onClick={() => {
                setAction([]);
                setDrawerTab("1");
              }}
              key={0}>
              <IconX />
            </ActionIcon>,
          ]}
          title={
            <div>
              {drawerTab === "1" && (
                <Text c={drawerTab === "1" ? "black" : "#2a3635"} fw={600}>
                  Эргэн төлөлтийн нэхэмжлэх
                  <Text ml={5} span c="golomt" inherit>
                    # {(action[1] && action[1]?.invRefCode) || "-"}
                  </Text>
                </Text>
              )}
              {drawerTab === "2" && (
                <Text c={drawerTab === "2" ? "black" : "#2a3635"} fw={600}>
                  Эргэн төлөлтийн нэхэмжлэх
                  <Text ml={5} span c="golomt" inherit>
                    # {(action[1] && action[1]?.invRefCode) || "-"}
                  </Text>
                </Text>
              )}
              {drawerTab === "3" && (
                <Text c={drawerTab === "3" ? "black" : "#2a3635"} fw={600}>
                  Сануулга түүх
                </Text>
              )}
              {drawerTab === "4" && (
                <Text c={drawerTab === "4" ? "black" : "#2a3635"} fw={600}>
                  И-мэйл сануулга
                </Text>
              )}
              {drawerTab === "5" && (
                <Text c={drawerTab === "5" ? "black" : "#2a3635"} fw={600}>
                  Амлалт түүх
                </Text>
              )}
            </div>
          }>
          <Tabs value={drawerTab} onTabChange={(value: string) => setDrawerTab(value)} h="100%">
            <Tabs.List>
              <Tabs.Tab icon={<IconFileCheck color={drawerTab === "1" ? `#3e166e` : "#49505A"} />} value="1">
                <Text fw={500} c={drawerTab === "1" ? "golomt" : "#49505A"}>
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconListDetails color={drawerTab === "2" ? `#3e166e` : "#49505A"} />} value="2">
                <Text fw={500} c={drawerTab === "2" ? "golomt" : "#49505A"}>
                  Төлөлтийн түүх
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconTimeline color={drawerTab === "3" ? `#3e166e` : "#49505A"} />} value="3">
                <Text fw={500} c={drawerTab === "3" ? "golomt" : "#49505A"}>
                  Сануулга түүх
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconMail color={drawerTab === "4" ? `#3e166e` : "#49505A"} />} value="4">
                <Text fw={500} c={drawerTab === "4" ? "golomt" : "#49505A"}>
                  И-мэйл сануулга
                </Text>
              </Tabs.Tab>
              <Tabs.Tab icon={<IconFileLike color={drawerTab === "5" ? `#3e166e` : "#49505A"} />} value="5">
                <Text fw={500} c={drawerTab === "5" ? "golomt" : "#49505A"}>
                  Амлалт түүх
                </Text>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="1">
              {action[1]?.id && drawerTab === "1" && <RepaymentDetail setSelectedData={setSelectedData} id={action[1]?.id as string} />}
            </Tabs.Panel>
            <Tabs.Panel value="2">{drawerTab === "2" && <PaymentHistory action={action} />}</Tabs.Panel>
            <Tabs.Panel value="3">{drawerTab === "3" && <RememberHistory action={action} />}</Tabs.Panel>
            <Tabs.Panel value="4">{drawerTab === "4" && <EmailNotify action={action} />}</Tabs.Panel>
            <Tabs.Panel value="5">{drawerTab === "5" && <PromiseHistory action={action} />}</Tabs.Panel>
          </Tabs>
        </PageLayout>
        <Modal size={400} centered title="SCF хүсэлт батлах" withCloseButton={false} opened={pinModal} onClose={() => setPinModal(false)}>
          <RepaymentPinForm setLoading={setLoading} loading={loading} sendRequest={sendRequest} onClose={() => setPinModal(false)} />
        </Modal>
      </Drawer>
    </div>
  );
};
